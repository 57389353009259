export default function sortFunction(aa, bb) {
    if (!aa && !bb) {
        return 0
    }
    if (!aa) {
        return 1
    }

    if (!bb) {
        return -1
    }
    return aa - bb;
}