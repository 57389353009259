import React from "react"

export default function BasicLayoutStructure({ header, left, right }) {

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', padding: '0 1rem', marginBottom: '1rem', }} >
            {header && <h1>
                {header}
            </h1>}

            <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                <div style={{ flex: '1 1 600px' }}>{left}</div>
                {right && <div style={{ margin: '1rem', flex: '0 1 400px' }}>{right}</div>}
            </div>
        </div>
    )
}