import React from "react";
import Markdown from "react-markdown";

export default function Contact({contact}) {
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }} className="contact">
            <h3>{contact.pavadinimas}</h3>
            {/* <div>{contact.tekstas}</div> */}
            <Markdown source={contact.tekstas} escapeHtml={false} />

            {/* <div>{address}</div> */}
            {/* <a href={"tel:" + phone}>{phone}</a> */}
            {/* <a href={"mailto:" + email}>{email}</a> */}
        </div>
    )
}