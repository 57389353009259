import React from "react";
import Layout from "../components/layout/layout";
import SEO from "../components/layout/seo";
import Contact from "../components/contacts/contact"
import { graphql, useStaticQuery } from "gatsby";
import BasicLayoutStructure from "../components/BasicLayoutStructure";
import sortFunction from "../components/sort";

const Kontaktai = () => {
  const data = useStaticQuery(query);
  let contacts = data
    .allStrapiKontaktai
    .nodes
    .sort((a, b) => sortFunction(a.prioritetas, b.prioritetas));

  return <Layout>
    <SEO
      seo={{
        metaTitle: "gimnastika.lt | Kontaktai",
      }}
    />
    <BasicLayoutStructure header="Kontaktai" left={
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginTop: '40px' }} className="contacts">
        {contacts.map((c, i) => <Contact contact={c} key={i} />)}
      </div>
    } />
  </Layout>
};

export default Kontaktai;

const query = graphql`
  query {
      allStrapiKontaktai {
      nodes {
      pavadinimas
      tekstas
      prioritetas
      }
    }
  }
`